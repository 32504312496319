import { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { Member } from './types';

export interface ProfileInfo {
  name?: string;
  title?: string;
  profilePrivacy?: Member['privacyStatus'];
}

type ProfileInfoChangeCallback = (publicInfo: ProfileInfo) => void;

interface MyAccountPublicApi {
  registerToProfileInfoChange: (callback: ProfileInfoChangeCallback) => void;
}

export class ProfileInfoChangeSubject {
  private observers: ProfileInfoChangeCallback[] = [];

  public registerObserver(callback: ProfileInfoChangeCallback) {
    this.observers.push(callback);
  }
  public unregisterObservers() {
    this.observers = [];
  }
  public notifyObservers(newPublicInfo: ProfileInfo) {
    this.observers.forEach((observer) => observer(newPublicInfo));
  }
}

const profileInfoChangeSubject = new ProfileInfoChangeSubject();

export const initAppForPage: InitAppForPageFn = async () => ({
  profileInfoChangeSubject,
});

export const exports = (): MyAccountPublicApi => ({
  registerToProfileInfoChange: (callback) =>
    profileInfoChangeSubject.registerObserver(callback),
});
